import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


export default function FormPropsTextFields() {
  const { uid } = useParams();

const navigate = useNavigate();

  const [formData, setFormData] = useState({
    EID: 0,
    UID: uid,
    Role: 3,
  });
  const [message, setMessage] = useState('');

  const currencies = [
    { value: '3', label: 'Intern' },
    { value: '2', label: 'Instructor' },
  ];

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData);
      

      const url = 'https://test.vrisharyatechnologies.com/api/UsrToEmp.php';
      const response = await axios.post(
        url,
        {
          EID: formData.EID,
          UID: formData.UID,
          Role: formData.Role,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.data === 'Employee registration successful.') {
        navigate("/employees")
      }else{
        setMessage(response.data);
      }

      
    } catch (error) {
      console.error(error);
      setMessage('Error submitting the form.');
    }
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 2, width: '100%', maxWidth: '400px' },
        '& .MuiButton-root': { m: 2 },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div>
        <h2>Employee Registration</h2>
        <TextField
          id="EID"
          label="EID"
          value={formData.EID}
          onChange={handleInputChange}
          helperText="Employee ID"
          variant="standard"
        />
        <TextField
          id="UID"
          label="UID"
          value={formData.UID}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
        <TextField
          id="Role"
          select
          label="Role"
          value={formData.Role}
          onChange={handleInputChange}
          helperText="Role of Employee"
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </div>
      {message && <p>{message}</p>}
    </Box>
  );
}
