import React, { useEffect } from 'react'
import { FaGithub } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { Link } from 'react-router-dom'

const Login = () => {

  useEffect(() => {
    let pageVerticalPosition = localStorage.getItem('loginTop') || 0;
    window.scrollTo(0, pageVerticalPosition);
  });

  return (
    <>  {/* bg-gradient-to-t from-blue-100 via-blue-300 to-blue-500 */}
      <div id='loginTop' className='bg-gradient-to-r from-blue-100 via-blue-300 to-blue-500 py-4'>
        <div className=" flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
            <h2 className="text-2xl font-semibold text-center mb-4">Login</h2>
            <p className="text-gray-600 text-center mb-6">Hello Again! & Welcome back.</p>
            <form>
              <div className="mb-4">
                <label for="email" className="block text-gray-700 text-sm font-semibold mb-2">Email Address *</label>
                <input type="email" id="email" className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500" required placeholder="abc@xyz.com" />
              </div>
              <div className="mb-6">
                <label for="password" className="block text-gray-700 text-sm font-semibold mb-2">Password *</label>
                <input type="password" id="password" className="form-input w-full px-4 py-2 border rounded-lg text-gray-700 focus:ring-blue-500" required placeholder="••••••••" />
                <p className="text-gray-600 text-xs mt-1">Must contain 1 uppercase letter, 1 number, min. 8 characters.</p>
              </div>
              <button type="submit" className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">Login</button>
            </form>
            <br />
            <div className="grid grid-cols-3 items-center text-gray-400">
              <hr className="border-gray-400" />
              <p className="text-center text-sm">OR</p>
              <hr className="border-gray-400" />
            </div>

            <div className='flex flex-row justify-between items-center w-full gap-4'>
              <button className="bg-white border w-full py-2 gap-3 rounded-xl mt-5 flex justify-center items-center  text-[#002D74] hover:scale-105 duration-200">
                <FcGoogle className='w-6 h-6' />
                <h2 className='font-semibold'>Google</h2>
              </button>
              <button className="bg-white border w-full py-2 gap-3 rounded-xl mt-5 flex justify-center items-center  text-[#002D74] hover:scale-105 duration-200">
                <FaGithub className='w-6 h-6' />
                <h2 className='font-semibold'>GitHub</h2>
              </button>
            </div>

            <p className="text-gray-600 text-xs text-center mt-4">
              Don't have an account yet?&nbsp;
              <Link to="/user" className="text-blue-500 hover:underline">Register</Link>.
            </p>
          </div>
        </div>
      </div >
    </>
  )
}

export default Login