import React, { Fragment, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { AiOutlineUserAdd } from "react-icons/ai"
import { Logo } from '../assets/index';
import { Link as ScrollLink } from "react-scroll"
import { Menu, Transition } from "@headlessui/react"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navbar = () => {
  const moreOptn = [
    { name: "Home", link: "/" },
    { name: "Registration", link: "/user" },
    { name: "Contact Us ", link: "/contact" },
    { name: "Login", link: "/login" },
  ]

  const navItems = [
    { name: "Home", id: "#", link: "home", to: "/" },
    { name: "About", id: "#about", link: "about", to: "/" },
    { name: "Services", id: "#services", link: "services", to: "/" },
    { name: "Testimonial", id: "#testimonial", link: "testimonial", to: "/" },
    { name: "Blog", id: "#blog", link: "blog", to: "/" },
    { name: "Contact Us", id: "#contact_us", link: "contact_us", to: "/" },
  ]

  const [isMobile] = useState(false);
  // const [sideMenu] = useState(false);
  const navigator = useNavigate();


  // useEffect(() => {
  //   const handleResize = () => {
  //     const windowWidth = window.innerWidth;
  //     setIsMobile(windowWidth <= 768);
  //   };

  //   window.addEventListener('resize', handleResize);
  //   handleResize();
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 500;
      setIsScrolled(scrollPosition > scrollThreshold);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleIdClick = (navId) => {
    navigator(`/${navId}`);
  };

  // useEffect(() => {
  //   if (sideMenu) {
  //     // Disable scrolling when sidebar is opened
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     // Enable scrolling when sidebar is closed
  //     document.body.style.overflow = 'auto';
  //   }

  //   // Cleanup effect on unmount or when sideMenu changes
  //   return () => {
  //     document.body.style.overflow = 'auto';
  //   };
  // }, [sideMenu]);

  useEffect(() => {
    const url = window.location.href;
    const hashIndex = url.indexOf('#');
    
    if (hashIndex !== -1) {
      const id = url.substring(hashIndex + 1);
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  });

  return (
    <>
      <div className={`${isMobile ? 'hidden' : "relative  px-4 py-3 w-full h-auto z-50"}`}>
        <nav className={`${isScrolled ? "fixed top-4 left-4 right-4 px-4" : "relative"} py-4 px-4 rounded-2xl bg-gray-50 shadow-xl flex justify-between items-center`}>
          <div className='px-12 flex items-center'>
            <img className="h-12 w-auto mr-5" src={Logo} alt="Vrisharya Technology" />
            <h2 className='text-blue-500 text-2xl font-semibold'>Vrisharya Technology</h2>
          </div>
          <div>
            <ul className='flex justify-center gap-1'>
              {navItems.map((item, idx) => (
                <li className='px-2' key={idx}>
                  <ScrollLink activeClass="active" onClick={() => handleIdClick(item.id)} to={item.link} spy={true} smooth={true} offset={-70} duration={1000}
                    className='text-[0.92rem] font-semibold text-blue-800 hover:text-blue-500 duration-300 cursor-pointer border-b-2 border-transparent hover:border-blue-900'>
                    {item.name}
                  </ScrollLink>
                </li>
              ))}
            </ul>
          </div>

          {/* Extra Menu */}
          <Menu as="div" className="relative ml-3">
            <div className='mr-4'>
              <Menu.Button className="relative flex rounded-full text-sm border-2 border-blue-400 w-9 h-9 items-center justify-center">
                <AiOutlineUserAdd className='h-5 w-5 rounded-full text-blue-400' />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {moreOptn.map((item, idx) => (
                  <Menu.Item>
                    {({ active }) => (
                      <NavLink key={idx} to={item.link} className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}>{item.name}</NavLink>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>

          {/* <div className='items-end mr-5'>
            <NavLink key="Login" to="/login"
              className={classNames(
                'bg-blue-700 text-white border-blue-600 hover:bg-transparent hover:text-blue-500 hover:ring-2  focus:outline-blue-800',
                'rounded-md px-4 py-2 font-medium'
              )} >Login
            </NavLink>
          </div> */}
        </nav>
      </div>

      {/* for mobile devices */}
      {/* <div className={`${!isMobile ? "hidden" : "block bg-slate-100 justify-between py-4 w-full"}`}> {/*flex *
        <nav className='relative items-center py-2 px-6'>
          <section className={`${!sideMenu ? "flex justify-between items-center" : "hidden"}`}>
            <div className='flex items-center '>
              <img className="h-12 w-auto mr-4 " src={Logo1} alt="Vrisharya Technology" />
              <h2 className='text-blue-500 text-2xl font-semibold'>Vrisharya Technology</h2>
            </div>
            <RiMenuAddLine className='text-2xl font-bold text-blue-700 cursor-pointer' onClick={() => setSideMenu(true)} />
          </section>

        {/* side bar for mobile *
          <div className={`${sideMenu ? "fixed h-full w-screen bg-black/50 backdrop-blur-sm top-0 left-0" : "hidden"}`}>
            <section className='text-black bg-white flex-col absolute right-0 top-0 h-screen p-8 gap-4 z-50 w-1/2 flex'>
              <RiCloseLine className="mt-0 ml-auto text-3xl text-blue-700 cursor-pointer mb-4" onClick={() => setSideMenu(false)} />
              {navLinks.map((item, idx) => (
                <NavLink key={idx} to={item.link} className=" px-4 py-3 text-blue-400 font-semibold hover:bg-blue-300 hover:text-white hover:rounded-md" onClick={() => setSideMenu(false)}>{item.name}</NavLink>
              ))}
              <NavLink key="Login" to="/login"
                className={classNames(
                  'bg-blue-700 text-white border-blue-600 hover:bg-transparent hover:text-blue-500 hover:ring-2  focus:outline-blue-800',
                  'rounded-md px-4 py-3 font-medium'
                )} onClick={() => setSideMenu(false)} >Login
              </NavLink>
            </section>
                </div>
        </nav>
                </div> */}
    </>
  )
}

export default Navbar
