import { BrowserRouter, Route, Routes } from "react-router-dom";
import UserProfile from './Users/UserProfile';
import UsersList from "./Users/UsersList";
import ContactUs from './contact_us/ContactUs';
import EmpList from "./Employees/EList";
import UserToEmployee from "./Employees/UserToEmployee";
import EmpTrack from "./TracksSelect/EmpTrack";
import { Home, Navbar, Footer, Login, Registration } from "./Components/index"


const App = () => {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route exact path='/' element={< Home />}></Route>
          <Route exact path='/userlist' element={< UsersList />}></Route>
          <Route path="/user/:userId" element={<UserProfile />} />
          <Route exact path='/contact' element={< ContactUs />}></Route>
          <Route exact path='/employees' element={< EmpList />}></Route>
          <Route exact path='/usrtoemp/:uid' element={< UserToEmployee />}></Route>
          <Route exact path='/trackselect/:uid' element={< EmpTrack />}></Route>

          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/user" element={<Registration />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
