import React from 'react'
import { portfolio1, portfolio2, portfolio3 } from '../assets';

const Services = () => {

  const sertviceItems = [
    {
      img: portfolio1,
      name: "Full-Stack Web Development",
      description: "We offer a comprehensive internship in Full Stack Web Development, equipping individuals with hands on experience, diverse skillsets, and practical knowledge across frontend and backend technologies for holistic professional growth.",
    },
    {
      img: portfolio2,
      name: "Flutter Development",
      description: "Our program offers immersive internships in Flutter development, equipping individuals with hands-on experience, mentorship, and real-world projects to foster expertise in this dynamic mobile app framework.",
    },
    {
      img: portfolio3,
      name: "AI & ML",
      description: "We provide detailed AI/ML internships that provide participants with practical experience, guidance, and real-world assignments. Our curriculum encourages development, creativity, and the acquisition of useful machine learning and artificial intelligence abilities.",
    },
  ]

  return (
    <>
      <div id="services" className='bg-slate-200 mt-10 pt-10 pb-24'>
        <div className='flex justify-center py-10'>
          <h1 className='font-bold text-[2.5rem] text-blue-500'>Our Services</h1>
        </div>
        <div className='flex justify-center items-center'>
          <div className={`${sertviceItems.length > 2 ? "grid grid-cols-3": "flex items-center"}  gap-10 justify-center`}>
            {sertviceItems.map((item, idx) => (
              <div key={idx} className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <img className="rounded-t-lg" src={item.img} alt="" />
              <div className="p-5">
                  <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{item.name}</h5>
                  <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{item.description}</p>
              </div>
            </div>
            ))}
          </div>
        </div>
      </div>

      {/* <ActiveSlider /> */}

    </>
  )
}

export default Services