import React from 'react'

const Registration = () => {
  return (
    <>
      <div className='w-full h-screen'>
        <iframe
          className='my-2 w-full h-screen'
          title='Registration Form'
          src="https://forms.vrisharyatechnologies.com/"
          frameborder="1"></iframe>
      </div>
    </>
  )
}

export default Registration