import React from 'react'
import { image8 } from '../assets'
import { FaArrowRightLong } from "react-icons/fa6"
import { NavLink } from 'react-router-dom'

const ContactUs = () => {
  return (
    <>
      <div id='contact_us' className='w-full justify-center items-center flex flex-col py-8 bg-white'>
      <div className='flex justify-center py-10'>
          <h1 className='font-bold text-[2.5rem] text-blue-500'>Contact Us</h1>
        </div>
        <div className="flex flex-col items-center bg-white border border-gray-200 rounded-lg shadow-lg md:flex-row md:max-w-xl hover:bg-gray-100 mb-14">
          <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-s-lg" src={image8} alt="" />
          <div className="flex flex-col justify-center px-4 leading-normal">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-blue-900">Vrisharya Technology Private Limited</h5>
            <p className="mb-3 font-normal text-gray-700">Connect for queries or collaborations. Contact us for educational solutions and partnerships. Let's build a better future together!</p>
            <NavLink to="/contact" className="gap-3 items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 flex justify-center w-1/2">
              <h1>Contact Us</h1>
              <FaArrowRightLong />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs