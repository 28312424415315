/* eslint-disable eqeqeq */
// AdminDashboard.js

import React, { useEffect, useState } from 'react';
import { finalUrl } from '../constants.jsx';

const ReadContactUs = () => {
    const [data, setData] = useState([]);
    const [showUndone, setShowUndone] = useState(false);

  useEffect(() => {
    // Fetch data from the PHP backend
    const url = finalUrl + '/read_contact_us.php';
    fetch(url)
      .then(response => response.json())
      .then(data => {
        // Update the state with the fetched data
        setData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []); // The empty dependency array ensures that this effect runs only once (on component mount)

  const handleUpdateIsDone = (inquiryID, isDone) => {
    // Determine the action based on the current isDone value
    const action = isDone==1 ? 'markUndone' : 'markDone';

    // Send a request to update the isDone field for the specific InquiryID
    const updateUrl = finalUrl + '/update_is_done.php';
    fetch(updateUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ inquiryID, action }),
    })
      .then(response => response.json())
      .then(updatedData => {
        // Update the state with the updated data
        setData(updatedData);
      })
      .catch(error => console.error(`Error updating isDone (${action}):`, error));
  };
  const filteredData = showUndone ? data.filter(item => item.isDone == 0) : data;


  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Contact Us</h1>
      <button onClick={() => setShowUndone(!showUndone)}>
        {showUndone ? 'Show All' : 'Show Undone Only'}
      </button>
      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>InquiryID</th>
            <th style={tableHeaderStyle}>Name</th>
            <th style={tableHeaderStyle}>Email</th>
            <th style={tableHeaderStyle}>Phone</th>
            <th style={tableHeaderStyle}>Subject</th>
            <th style={tableHeaderStyle}>Message</th>
            <th style={tableHeaderStyle}>InquiryDate</th>
            <th style={tableHeaderStyle}>isDone</th>
            <th style={tableHeaderStyle}>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map(item => (
            <tr key={item.InquiryID} style={{ borderBottom: '1px solid #ddd' }}>
              <td style={tableCellStyle}>{item.InquiryID}</td>
              <td style={tableCellStyle}>{item.Name}</td>
              <td style={tableCellStyle}>{item.Email}</td>
              <td style={tableCellStyle}>{item.Phone}</td>
              <td style={tableCellStyle}>{item.Subject}</td>
              <td style={tableCellStyle}>{item.Message}</td>
              <td style={tableCellStyle}>{item.InquiryDate}</td>
              <td style={tableCellStyle}>{item.isDone}</td>
              <td style={tableCellStyle}>
                <button
                  onClick={() => handleUpdateIsDone(item.InquiryID, item.isDone)}
                  style={{ padding: '8px', cursor: 'pointer', background: item.isDone==1 ? '#f44336' : '#4CAF50', color: 'white', border: 'none', borderRadius: '4px' }}
                >
                  {item.isDone==1 ? 'Undo' : 'Done'}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const tableHeaderStyle = {
  background: '#f2f2f2',
  padding: '12px',
  textAlign: 'left',
  borderBottom: '1px solid #ddd',
};

const tableCellStyle = {
  padding: '12px',
  textAlign: 'left',
};

export default ReadContactUs;
