import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


export default function FormPropsTextFields() {
  const { uid } = useParams();

const navigate = useNavigate();

  const [formData, setFormData] = useState({
    TID: 0,
    EID: uid,
    registered_on: dayjs('2019-01-25').format('YYYY-MM-DD'),
  });
  const [message, setMessage] = useState('');



  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      console.log(formData);
      

      const url = 'https://test.vrisharyatechnologies.com/api/EmpToTracks.php';
      const response = await axios.post(
        url,
        {
          TID: formData.TID,
          EID: formData.EID,
          registered_on: formData.registered_on,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      if (response.data === 'Employee registration successful.') {
        navigate("/employees")
      }else{
        setMessage(response.data);
      }

      
    } catch (error) {
      console.error(error);
      setMessage('Error submitting the form.');
    }
  };

  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 2, width: '100%', maxWidth: '400px' },
        '& .MuiButton-root': { m: 2 },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <div>
        <h2>Employee Registration</h2>
        <TextField
          id="TID"
          label="TID"
          value={formData.TID}
          onChange={handleInputChange}
          helperText="Employee ID"
          variant="standard"
        />
        <TextField
          id="EID"
          label="EID"
          value={formData.EID}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    label="Controlled picker"
                    value={dayjs(formData.registered_on, 'YYYY-MM-DD')}
                    onChange={(newValue) => {
                        formData.registered_on = newValue.format('YYYY-MM-DD');
                    }}
                />
            </DemoContainer>
        </LocalizationProvider>

        <Button type="submit" variant="contained">
          Submit
        </Button>
      </div>
      {message && <p>{message}</p>}
    </Box>
  );
}
